<template>
  <div>

    <b-overlay :show="dataLoading" rounded="sm">
      <modal-new-order
          :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
          :role-options="roleOptions"
          :plan-options="planOptions"
          @refetch-data="refetchData"
      />

      <!-- Враппер таблицы -->

      <b-card
          no-body
          class="mb-0"
      >

        <!-- Шапка таблицы -->
        <div class="m-2">
          <b-row class="justify-content-between d-flex flex-wrap">

            <!-- Показывать... -->
            <b-col
                cols="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать</label>
              <v-select
                  v-model="pagination.perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pagination.options"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="loadOrders"
              />
            </b-col>


            <!-- Поиск -->
            <b-col cols="9">
              <b-row class="justify-content-end">

                <!--                &lt;!&ndash; Поиск &ndash;&gt;-->
                <!--                <b-col-->
                <!--                    cols="8"-->
                <!--                    md="8"-->
                <!--                >-->
                <!--                  <b-row>-->
                <!--                    <b-col cols="2" class="d-flex align-items-center justify-content-end "> Поиск:</b-col>-->
                <!--                    <b-col cols="10" class="d-flex align-items-center justify-content-end ">-->
                <!--                      <b-form-input-->
                <!--                          v-model="searchQuery"-->
                <!--                          class="d-inline-block mr-1"-->
                <!--                          placeholder="Поиск по фамилии, имени, организации"-->
                <!--                      />-->
                <!--                    </b-col>-->
                <!--                  </b-row>-->
                <!--                </b-col>-->

                <!--  Новый клиент  -->
                <b-button
                    variant="primary"
                    class="mr-1"
                    @click="$router.push('/cart')"
                >
                  <span class="text-nowrap">Новый заказ</span>
                </b-button>
              </b-row>
            </b-col>
          </b-row>

        </div>

        <!--  Таблица  -->
        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="items"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Нет подходящих результатов"
            :sort-desc.sync="isSortDirDesc"
        >

          <!-- Номер заказа -->
          <template #cell(id)="data">
            <b-media vertical-align="center">
              <a @click="$router.push(`/orders/${data.item.id}`)" >{{ data.item.id }}</a>
            </b-media>
          </template>

          <!-- Дата создания -->
          <template #cell(create_at)="data">
            <b-media vertical-align="center">
              <span>{{ data.item.create_at | fromIsoDateTime }}</span>
            </b-media>
          </template>

          <!-- Статус клиента -->
          <template #cell(status)="data">
            <b-badge
                pill
                :variant="`light-${resolveOrderStatusVariant(data.item.status)}`"
                class="text-capitalize"
            >
              {{ resolveOrderStatusLabel(data.item.status) }}
            </b-badge>
          </template>

          <!-- Номер заказа -->
          <template #cell(totalAmount)="data">
            {{data.item.totalAmount | formatNumber}}
          </template>

          <!--  Клиент  -->
          <template #cell(user)="data">
            <!--          <b>{{data.item.id}}</b>-->
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="data.item.user.avatar"
                    :text="avatarText(getFullname(data.item.user))"
                    :to="{ name: 'client-page', params: { id: data.item.user.id } }"
                />
              </template>
              <!--            <b>{{ data.item.user.last_name }} {{ data.item.user.first_name }} </b>-->
              <b-link
                  :href="`/clients/${data.item.user.id}`"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ getFullname(data.item.user) }}
              </b-link>
            </b-media>
          </template>

          <!-- Управление -->
          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'order', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">Подробнее</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteOrder(data.item.id)">
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50" @click="deleteOrder(data.item.id)">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <!--  Показаны 10 из 30 результатов -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Показаны {{ dataMeta.from }} - {{ dataMeta.to }} из {{ dataMeta.of }} Результатов</span>-->
            </b-col>

            <!-- Паджинация -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="pagination.currentPage"
                  :total-rows="pagination.rows"
                  :per-page="pagination.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="loadOrders"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText} from '@core/utils/filter'
import useUsersList from '../clients/useUsersList'
import userStoreModule from '@/store/app/userStoreModule'
import ModalNewOrder from "@/components/views/orders/ModalNewOrder"

import api from '@/api'
import utils from "@/utils";

export default {
  components: {
    ModalNewOrder,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      {label: 'Admin', value: 'admin'},
      {label: 'Author', value: 'author'},
      {label: 'Editor', value: 'editor'},
      {label: 'Maintainer', value: 'maintainer'},
      {label: 'Subscriber', value: 'subscriber'},
    ]

    const planOptions = [
      {label: 'Basic', value: 'basic'},
      {label: 'Company', value: 'company'},
      {label: 'Enterprise', value: 'enterprise'},
      {label: 'Team', value: 'team'},
    ]

    const statusOptions = [
      {label: 'В работе', value: 'IN_PROGRESS'},
      {label: 'Выполнен', value: 'COMPLETED'},
      {label: 'Не отправлен', value: 'CREATED'},
    ]

    const {
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusLabel,

    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusLabel,

      roleOptions,
      planOptions,
      statusOptions,
    }
  },
  filters: {
    fromIsoDateTime(value) {
      console.log(value)
      const date = new Date(value)
      console.log(date.getFullYear(), date.getMonth(), date.getDate())
      console.log(date.toString())
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
      return date.toString()
    }

  },
  mounted() {
    this.loadOrders()
  },
  data() {
    return {
      dataLoading: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        options: [10, 25, 50, 100],
        rows: 100,
      },
      tableColumns: [
        {key: 'id', label: 'Номер заказа', sortable: true},
        {key: 'create_at', label: 'Дата заказа', sortable: true},
        {key: 'status', label: 'Статус заказа', sortable: true},
        {key: 'totalCount', label: 'Количество позиций', sortable: true},
        {key: 'totalAmount', label: 'Сумма заказа', sortable: true},
        {key: 'user', label: 'Создатель заказа', sortable: true},
        {key: 'actions', label: 'Упр'},
      ],
      // items: [
      //     {order_number: '000001', order_date: '10/10/2021', status: 'active', counter_product: 2, price: 1000, creator: {
      //         id: 1,
      //         registration_date: '2.12.21',
      //         fullName: 'Galen Slixby',
      //         company: 'Yotz PVT LTD',
      //         role: 'editor',
      //         username: 'gslixby0',
      //         country: 'El Salvador',
      //         contact: '(479) 232-9151',
      //         email: 'gslixby0@abc.net.au',
      //         currentPlan: 'enterprise',
      //         status: 'inactive',
      //         avatar: '',
      //         privilege: '2 Уровень',
      //       },},
      //     {order_number: '000002', order_date: '10/10/2021', status: 'pending', counter_product: 2, price: 1000, creator: {
      //         id: 2,
      //         registration_date: '2.12.21',
      //         fullName: 'Halsey Redmore',
      //         company: 'Skinder PVT LTD',
      //         role: 'author',
      //         username: 'hredmore1',
      //         country: 'Albania',
      //         contact: '(472) 607-9137',
      //         email: 'hredmore1@imgur.com',
      //         currentPlan: 'team',
      //         status: 'pending',
      //         avatar: require('@/assets/images/avatars/10.png'),
      //         privilege: '1 Уровень',
      //       },},
      //     {order_number: '000003', order_date: '10/10/2021', status: 'inactive', counter_product: 2, price: 1000, creator: {
      //         id: 2,
      //         registration_date: '2.12.21',
      //         fullName: 'Halsey Redmore',
      //         company: 'Skinder PVT LTD',
      //         role: 'author',
      //         username: 'hredmore1',
      //         country: 'Albania',
      //         contact: '(472) 607-9137',
      //         email: 'hredmore1@imgur.com',
      //         currentPlan: 'team',
      //         status: 'pending',
      //         avatar: require('@/assets/images/avatars/10.png'),
      //         privilege: '1 Уровень',
      //       }, },
      // ],
      items: [],
    }
  },
  methods: {
    getFullname(user) {
      if (user.account_type === 'ADMIN')
        return 'Администратор'
      return user.fullName;

    },
    loadOrders() {
      const self = this;
      this.dataLoading = true;
      const offset = (this.pagination.currentPage - 1) * this.pagination.perPage
      const limit = this.pagination.perPage
      api.orders.getAll(offset, limit)
          .then(response => {
            self.items = response.data.result.items;
            for (let order of self.items) {

              order.user.fullname = `${order.user.first_name} ${order.user.last_name}`


            }
            self.pagination.rows = response.data.result.count;
            self.dataLoading = false
          })


    },
    deleteOrder(id) {
      const self = this;
      this.dataLoading = true
      api.orders.deleteOrder(id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.loadOrders()
              utils.notificate(self, data.message)
              self.dataLoading = false
            }
          })
          .catch((err) => {
            self.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });


    },
    resolveOrderStatusVariant(status) {
      if (status === 'IN_PROGRESS') return 'warning'
      if (status === 'COMPLETED') return 'success'
      if (status === 'CREATED') return 'danger'
      return 'primary'
    },
    resolveOrderStatusLabel(status) {
      if (status === 'IN_PROGRESS') return 'В работе'
      if (status === 'COMPLETED') return 'Выполнен'
      if (status === 'CREATED') return 'Не отправлен'
    },
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
