<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
         Добавить нового пользователя
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
          v-slot="{invalid}"
      >
        <!-- Форма -->
        <b-form
          class="p-2"
          @submit.prevent
        >

          <!-- Имя -->
          <BlInput rules="alpha_spaces|required"
                   name="firstName"
                   placeholder="Иван"
                   input-type="text"
                   @changeData="changeFirstName"
                   label="Имя"></BlInput>

          <!-- Фамилия -->
          <BlInput rules="alpha_spaces|required"
                   name="lastName"
                   placeholder="Иванов"
                   input-type="text"
                   @changeData="changeLastName"
                   label="Фамилия"></BlInput>

          <!-- Email -->
          <BlInput rules="email|required"
                   name="email"
                   placeholder="email@gmail.com"
                   input-type="text"
                   @changeData="changeEmail"
                   label="Email"></BlInput>

          <!-- Название компании -->
          <BlInput rules="required"
                   name="company_name"
                   placeholder="ООО Свет"
                   input-type="text"
                   @changeData="changeCompanyName"
                   label="Наименование компании"></BlInput>

          <!-- Кнопки -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="postClient"
              :disabled="invalid"
            >
              Добавить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BlInput from "@/components/controls/BlInput";
import store from '@/store'
import '@/libs/custom-validate'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BlInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    changeFirstName(data) {
      this.client.firstName = data.data
    },
    changeLastName(data) {
      this.client.lastName = data.data
    },
    changeCompanyName(data) {
      this.client.company_name = data.data
    },
    changeEmail(data) {
      this.client.email = data.data
    },
    postClient(){
      alert('Форма отправлена')
    }
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      client: {
        firstName: '',
        lastName: '',
        email: '',
        company_name: '',
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
